import React, { Component } from "react"
import { Link } from "gatsby"

class Banner extends Component {
  state = {

  }

  componentDidMount () {

  }


  render() {
    const contentItems = this.props.contentItems
    const module = this.props.module
    let width

    if (contentItems.length > 1) {
      width = "max-w-4xl"
    } else {
      width = "max-w-xl"
    }

    return (  
      <div className={`${width} flex mx-auto my-10`}>
        {contentItems.map(item => {
          let link = module.target
          if (link) {
            return (
              <a href={module.target.resource.url} className="m-3 cursor-pointer">
                <img src={item.reference.resource.images[4].url} />
              </a>
            )
          } else { 
            return (
              <div className="m-3">
                <img src={item.reference.resource.images[4].url} />
              </div>
            )
          }
        })}
      </div>

    )

  }

}

export default Banner
