import React, { Component } from "react"

class Article extends Component {
  state = {

  }

  componentDidMount () {

    
  }


  render() {
    const contentItems = this.props.contentItems
    const module = this.props.module
    const title = JSON.parse(module.internal.content).title
    const content = JSON.parse(module.internal.content).content
    return (  
      <div className="max-w-md mx-auto my-8">
        <div className="uppercase text-3xl">
          {title}
        </div>
        <div className="mt-4 text-sm">
          {content}
        </div>
      </div>

    )

  }

}

export default Article
