import React, { Component } from "react"

class Video extends Component {
  state = {

  }

  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  getVideo(url) {
    const videoId = this.getId(url)

    return(
      <div class='embed-container'><iframe src={`//www.youtube.com/embed/${videoId}`} frameborder='0' allowfullscreen></iframe></div>
    )
  }


  render() {
    const module = this.props.module
    const youtubeUrl = JSON.parse(module.internal.content).target.resource.url
    return (  
      <div className="max-w-xl mx-auto my-10 px-4">
        {this.getVideo(youtubeUrl)}
      </div>

    )

  }

}

export default Video
