import React, { Component } from "react"

class Code extends Component {
  state = {

  }

  componentDidMount () {

  }

  contentEl(content) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }


  render() {
    const contentItems = this.props.contentItems
    const module = this.props.module
    const code = JSON.parse(module.internal.content).content
    return (  
      <div className="launchCode text-center m-5">
        {this.contentEl(code)}
      </div>

    )

  }

}

export default Code
