import React, { Component } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import { getToken } from "../utils/getToken"
import LoadingBar from 'react-top-loading-bar'

import Banner from "../components/launch/banner"
import Image from "../components/launch/banner"
import Article from "../components/launch/article"
import Video from "../components/launch/video"
import Code from  "../components/launch/code"
import logo from '../../static/k7-logo.svg'

class Launch extends Component {
  _isMounted = false
  state = {
    contentItems: [],
    fetchedContentItems: false,
    loadingBarProgress: 0,
    loadingComplete: false,
  }

  add = value => {
    this.setState({
      loadingBarProgress: this.state.loadingBarProgress + value
    });
  };
 
  complete = () => {
    this.setState({ loadingBarProgress: 100 });
  };
 
  onLoaderFinished = () => {
    this.setState({ loadingBarProgress: 0 });
  };
 

  fetchContentItems(options) {
    const modules = this.props.data.allModules.edges
    let modulesFetches = []
    modules.map( item => {
      const module = item.node
      const content = JSON.parse(module.internal.content)
      const url = 'https://api.ochre.io/v1/cms/modules/'+ module.itemId +'/content-items?'
      const fetch = axios.get(url, options) 
      modulesFetches.push(fetch)
    })

    Promise.all( modulesFetches ).then( (results) => {
      this.setState({
        contentItems: results,
        fetchedContentItems: true
      })
      setTimeout(function() {
        this.setState({loadingComplete: true})
        this.complete()
      }.bind(this),2000);
    })
  }

  componentDidMount () {
    if (!this.state.loadingComplete) {
      this.LoadingBar.continuousStart()
    }
    getToken().then(options => {
      this.fetchContentItems(options)
    })
  }


  render() {
    let modulesEl
    if (this.state.fetchedContentItems) {
      const modules = this.props.data.allModules.edges
      modulesEl = modules.map( (item, index) => {
        const module = item.node
        const type = module.type
        const contentItems = this.state.contentItems[index].data.results
        if (type == "banner") {
          return <Banner module={module} index={index} contentItems={contentItems} />
        } else if (type == "code") {
          return <Code module={module} index={index} contentItems={contentItems}/>
        } else if (type == "image") {
          return <Image module={module} index={index} contentItems={contentItems}/>
        } else if (type == "article") {
          return <Article module={module} index={index} contentItems={contentItems}/>
        } else if (type == "video") {
          return <Video module={module} index={index} contentItems={contentItems}/>
        }
      })
    }

    let loadingStyleDefault = {
      transitionProperty: 'opacity',
      transitionDuration: '.5s',
      opacity:1,
      pointerEvents: 'none', 
    }
    
    let loadingStyle = {
      ...loadingStyleDefault
    }

    if (this.state.loadingComplete) {
      loadingStyle = {
        ...loadingStyleDefault,
        opacity: 0,
      }
    }

    const loadingScreen = <div 
      style={loadingStyle}
      className="z-0 w-full fixed h-full bg-white top-0 left-0 flex justify-center text-center"
    >
      <img className="inline-block" src={logo} width="80" />
    </div>

    return (
      <div>
          <LoadingBar
            progress={this.state.loadingBarProgress}
            height={3}
            color='#000000'
            onLoaderFinished={() => this.onLoaderFinished()}
            onRef={ref => (this.LoadingBar = ref)}
          />
          {loadingScreen}
          {modulesEl}
      </div>
    )
  }

}

export const query = graphql`
query($slug: String!) {
  allModules(filter: {view: {slug: {eq: $slug}}}) {
    edges {
      node {
        itemId
        type
        internal {
          content
        }
        target {
          resource {
            url
          }
        }
      }
    }
  }
}
`

export default Launch
